<template>
  <div class="box">
    <div class="top">
      <img src="@/img/vip/bg-y.png" class="top-bg" v-if="userInfo.vip == 1" />
      <img src="@/img/vip/bg-n.png" class="top-bg" v-else />
      <div class="head-box">
        <img :src="userInfo.userPhotoFullPath" alt="" class="head-img" />
        <div>
          <div class="user-name">{{ userInfo.username }}</div>
          <div class="vip-status" v-if="userInfo.vip == 1">
            {{ userInfo.userVip.expireDate | date }}
          </div>
          <div class="vip-status" v-else>暂未开通vip</div>
        </div>
      </div>
      <div class="tag-box">
        <div class="tag-hint" v-if="userInfo.vip == 1">
          VIP会员<span>尊享权益生活</span>
        </div>

        <div class="tag-hint" v-else>
          购买VIP会员后<span>可获得如下特权</span>
        </div>
        <div class="tag">
          <img src="@/img/vip/tag1.png" class="tag-img" />
          <div class="tag-name">融资建议</div>
        </div>
        <div class="tag">
          <img src="@/img/vip/tag2.png" class="tag-img" />
          <div class="tag-name">贷款顾问</div>
        </div>
        <div class="tag">
          <img src="@/img/vip/tag3.png" class="tag-img" />
          <div class="tag-name">精准匹配</div>
        </div>
        <div class="tag">
          <img src="@/img/vip/tag4.png" class="tag-img" />
          <div class="tag-name">学办贷款</div>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="title">Vip权益解读</div>
      <div class="info-name" style="top: 30px">融资建议</div>
      <div class="info-nr" style="top: 36px">
        准入失败原因可查询 <br />
        企业预授信额度每月更新 <br />
        企业信用分每月更新<br />
        企业经营风险提示
      </div>
      <div class="info-name" style="top: 144px">专属顾问</div>
      <div class="info-nr" style="top: 151px">
        人工在线，一对一贷前、贷中、贷后服务<br />
        贷前资格预审，提高审批通过率<br />
        贷中在线答疑，指导填写贷款资料<br />
        如准入失败，根据提示帮助企业完善不足
      </div>
      <div class="info-name" style="top: 260px">银行匹配</div>
      <div class="info-nr" style="top: 267px">
        无需查询企业征信<br />
        每月尊享额度更新机会<br />
        企业能办理的贷款产品列表<br />
        并按额度、利息、用过滤综合排序推荐
      </div>
      <div class="info-name" style="top: 376px">学办贷款</div>
      <div class="info-nr" style="top: 382px">
        企业主必听的二十一节课<br />
        财务必学的十二种融资模式<br />
        教你从银行手机拿到更多资金
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-left">{{ vipInfo.vipName }}</div>
      <div class="bottom-center">
        <div class="price">
          ￥{{ vipInfo.realPrice }} <span>原价{{ vipInfo.oldPrice }}</span>
        </div>
        <div class="price-hint">热销中！超值体验</div>
      </div>
      <div class="pay" @click="order">立即开通</div>
    </div>
    <div v-html="alipayWap" ref="alipayWap"></div>
  </div>
</template>
<script src="http://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script>
import url from "@/service/url-config";

export default {
  data() {
    return {
      userInfo: {
        userPhotoFullPath: require("@/img/my/user-photo.png"),
      },
      alipayWap: "",
      vipInfo: {},
    };
  },
  filters: {
    date(val) {
      let date = new Date(val);
      let y = date.getFullYear(); // 年
      let MM = date.getMonth() + 1; // 月
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate(); // 日
      d = d < 10 ? "0" + d : d;
      let h = date.getHours(); // 时
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes(); // 分
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds(); // 秒
      s = s < 10 ? "0" + s : s;
      var timer = y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
      return timer;
    },
  },
  created() {
    this.$http.get(url.userInfo).then((res) => {
      this.userInfo = res.data;
      if (!this.userInfo.userPhotoFullPath) {
        this.userInfo.userPhotoFullPath = require("@/img/my/user-photo.png");
      }
    });
    this.getVip();
  },
  methods: {
    toInfo(index) {
      this.$router.push({
        path: "caseInfo",
        query: { index: index },
      });
    },
    getVip() {
      this.$http.get(url.getVipList).then((res) => {
        this.vipInfo = res.data[0];
      });
    },
    //获取订单
    order() {
      this.$http
        .get(url.saveUserOrder + `?vipId=${this.vipInfo.vipId}`)
        .then((res) => {
          this.pay(res.data);
        });
    },
    //支付
    pay(order) {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.$http
          .post(url.aaaa, {
            amount: order.realPrice, //金额
            description: order.vipName, //订单描述
            purpose: 1, //  订单用途 传1
            productType: 1, //产品类型  传2
            busOrderId: order.orderNum, // 业务订单号
          })
          .then((res) => {
            WeixinJSBridge.invoke(
              "getBrandWCPayRequest",
              // res.data,
              {
                appId: res.data.appid,
                nonceStr: res.data.noncestr,
                package: res.data.package,
                paySign: res.data.paySign,
                signType: res.data.signType,
                timeStamp: res.data.timestamp,
              },
              function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  // 使用以上方式判断前端返回,微信团队郑重提示：
                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                }
              }
            );
          });
      } else {
        this.$http
          .post(url.wapPayAli, {
            amount: order.realPrice, //金额
            description: order.vipName, //订单描述
            purpose: 2, //  订单用途 传1
            productType: 2, //产品类型  传2
            busOrderId: order.orderNum, // 业务订单号
          })
          .then((res) => {
            this.alipayWap = res.data;
            this.$nextTick(() => {
              this.$refs.alipayWap.children[0].submit();
            });
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  // background: url("../../img/vip/no-bg.png");
  background: #080409;
  background-size: 100%;
  min-height: 100vh;
  padding-bottom: 82px;
  // text-align: center;
  .top {
    width: 100%;
    height: 300px;
    position: relative;
    .top-bg {
      width: 298px;
      height: 237px;
      position: absolute;
      right: 0;
    }
    .head-box {
      position: absolute;
      top: 80px;
      left: 15px;
      width: 80%;
      display: flex;
      .head-img {
        width: 40px;
        height: 40px;
        margin-right: 12px;
        border-radius: 50%;
      }
      .user-name {
        font-size: 15px;
        color: #ffffff;
        font-weight: bold;
      }
      .vip-status {
        color: #9a9ea7;
        font-size: 12px;
      }
    }
    .tag-box {
      width: 350px;
      height: 134px;
      background: linear-gradient(90deg, #303145, #44495e);
      box-shadow: 0px 6px 31px 0px rgba(21, 21, 34, 0.67);
      border-radius: 15px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -175px;
      .tag-hint {
        font-size: 12px;
        color: #ffffff;
        width: 100%;
        margin-left: 24px;
        margin-top: 21px;
        span {
          color: #f3cf9e;
        }
      }
      .tag {
        width: 50px;
        img {
          width: 45px;
          height: 45px;
        }
        div {
          font-size: 12px;
          color: #f3d79c;
        }
      }
    }
  }
  .info {
    width: 350px;
    height: 458px;
    background: rgba(8, 4, 9, 0);
    border: 2px solid #caad86;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 38px;
    position: relative;
    .title {
      position: absolute;
      font-size: 16px;
      font-weight: bold;
      color: #f3cf9e;
      width: 110px;
      background: #080409;
      text-align: center;
      top: -15px;
      left: 50%;
      margin-left: -55px;
    }
    .info-name {
      width: 91px;
      height: 25px;
      background: linear-gradient(90deg, #f8d9ad, #e9b87a);
      border-radius: 0px 13px 13px 0px;
      color: #252531;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      line-height: 25px;
      position: absolute;
      left: -2px;
    }
    .info-nr {
      font-size: 12px;
      color: #dcdddf;
      line-height: 24px;
      position: absolute;
      left: 122px;
    }
  }
  .bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    height: 55px;
    background: linear-gradient(90deg, #303145, #44495e);
    .bottom-left {
      color: #ffffff;
      font-size: 19px;
      width: 102px;
      text-align: center;
      font-weight: bold;
      height: 32px;
      border-right: 1px dashed #f5e4b9;
    }
    .bottom-center {
      width: 148px;
      padding-left: 10px;
      .price {
        font-size: 18px;
        color: #f3cf9e;
        font-weight: bold;
        span {
          font-size: 12px;
          color: #999999;
          text-decoration: line-through;
        }
      }
      .price-hint {
        color: #999999;
        font-size: 15px;
      }
    }
    .pay {
      width: 125px;
      height: 55px;
      background: linear-gradient(90deg, #f8d9ad, #e9b87a);
      font-size: 18px;
      color: #22222e;
      text-align: center;
      line-height: 55px;
      font-weight: bold;
    }
  }
  .img {
    width: 239px;
    height: 172px;
  }
  .text {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #113fc4;
    margin: 15px;
  }
  .button {
    width: 200px;
    height: 40px;
    font-size: 14px;
  }
  .tag-img {
    width: 43px;
    height: 43px;
  }
  .tag-text {
    font-size: 10px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #999999;
  }
}
</style>
